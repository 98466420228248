<template>
    <vue-progress-bar></vue-progress-bar>
</template>

<script>
import VueProgressBar from 'vue-progressbar'
import Vue from 'vue'
import axios from 'axios'

export default {
    props: {
        color: {
            type: String,
            required: false,
            default: 'rgba(32, 152, 250, 0.8)'
        },
        failedColor: {
            type: String,
            required: false,
            default: 'rgba(214, 21, 21, 0.8)'
        },
        thickness: {
            type: String,
            required: false,
            default: '5px'
        },
        transitionSpeed: {
            type: String,
            required: false,
            default: '0.05s'
        },
        transitionOpacity: {
            type: String,
            required: false,
            default: '0.2s'
        },
        transitionTermination: {
            type: Number,
            required: false,
            default: 500
        },
        autoRevert: {
            type: Boolean,
            required: false,
            default: true
        },
        location: {
            type: String,
            required: false,
            default: 'top'
        },
        inverse: {
            type: Boolean,
            required: false,
            default: false
        }
    },
	created() {
        Vue.use(VueProgressBar, {
            color: this.color,
            failedColor: this.failedColor,
            thickness: this.thickness,
            transition: {
                speed: this.transitionSpeed,
                opacity: this.transitionOpacity,
                termination: this.transitionTermination
            },
            autoRevert: this.autoRevert,
            location: this.location,
            inverse: this.inverse
        })
    },
    mounted() {
        axios.interceptors.request.use(config => {
            this.$Progress.start()
            return config
        })

        axios.interceptors.response.use(response => {
            this.$Progress.finish()
            return response
        }, error => {
            this.$Progress.fail()
            return Promise.reject(error)
        })
    }
}
</script>
